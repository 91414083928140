import React from "react";

export type TabItem<T extends string> = {
  id: T;
  title: string;
  content: React.ReactNode;
};

type TabsProps<T extends string> = {
  tabs: TabItem<T>[];
  activeTab: T;
  onTabChange: (tab: T) => void;
};

export function Tabs<T extends string>({
  tabs,
  activeTab,
  onTabChange,
}: TabsProps<T>) {
  const activeTabItem = tabs.find((tab) => tab.id === activeTab);

  const handleTabClick = (id: T) => {
    onTabChange(id);
  };

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
  };

  const tabListStyle: React.CSSProperties = {
    display: "flex",
    borderBottom: "1px solid #ccc",
  };

  const tabStyle: React.CSSProperties = {
    padding: "10px 10px",
    cursor: "pointer",
    background: "none",
    border: "none",
    // Apparently, setting borderBottom to none is necessary
    // even though "border: none" is already set.
    // Tested in firefox.
    borderBottom: "none",
    outline: "none",
  };

  const activeTabStyle: React.CSSProperties = {
    ...tabStyle,
    borderBottom: "2px solid black",
    fontWeight: "bold",
  };

  const contentStyle: React.CSSProperties = {
    paddingTop: "20px",
  };

  return (
    <div style={containerStyle}>
      <div style={tabListStyle}>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            style={tab.id === activeTab ? activeTabStyle : tabStyle}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div style={contentStyle}>{activeTabItem?.content}</div>
    </div>
  );
}
